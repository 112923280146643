import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '../../class/utilities';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform<T>(array: Array<T>, searchString: any, property: keyof T | Array<keyof T>): Array<T> {
    if (!array || !property) {
      return []
    }


    if (Array.isArray(property)) {
      if(!array.length){
        return []
      }
      return array.filter(item => {
        return property.some(p => {
          if (searchString === undefined || searchString === null) {
            return item[p] === searchString;
          }
          switch (typeof searchString) {
            case 'boolean':
              return item[p] === searchString;
            case 'number':
              return `${item[p]}`.includes(`${searchString}`);
            case 'string':
            default:
              const match = Utilities.removeDiacritics(Utilities.removeCharacters(searchString)).toLocaleLowerCase();
              return Utilities.removeDiacritics(Utilities.removeCharacters(`${item[p]}`)).toLowerCase().includes(match)
          }
          // return item[p] !== undefined && Utilities.removeDiacritics(Utilities.removeCharacters(`${item[p]}`)).toLowerCase().includes(match)
        })
      })
    } else {
      return array.filter(item => {
        if (searchString === undefined || searchString === null) {
          return item[property] === searchString;
        }
        switch (typeof searchString) {
          case 'boolean':
            return item[property] === searchString;
          case 'number':
            return `${item[property]}`.includes(`${searchString}`);
          case 'string':
          default:
            const match = Utilities.removeDiacritics(Utilities.removeCharacters(searchString)).toLocaleLowerCase();
            return Utilities.removeDiacritics(Utilities.removeCharacters(`${item[property]}`)).toLowerCase().includes(match)
        }

        // return item[property] !== undefined && Utilities.removeDiacritics(Utilities.removeCharacters(`${item[property]}`)).toLowerCase().includes(match);
      });
    }

  }

}
