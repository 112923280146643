import { Pipe, PipeTransform } from '@angular/core';
import { MkgOS } from 'src/app/class/mkg-os';
import { DESCRIPTIVE_ITEM_STATUS } from '../../interface/ro-part-temp'

@Pipe({
  name: 'osTab'
})
export class OsTabPipe implements PipeTransform {

  transform(tabName: string, os?: MkgOS): string {
    let resp = ""
    switch (tabName) {
      case "ro_part":
        if (os && (os.parts?.length || os.partsTMP?.length)) {
          resp += ` (${(os.parts.length || 0) + (os.partsTMP.filter(part => (part.status === DESCRIPTIVE_ITEM_STATUS.DESCRIPTIVE_APPROVED_AND_ACTIVE || part.status === DESCRIPTIVE_ITEM_STATUS.ACTIVE)).length || 0)})`;
        }
        break;
      case "ro_labor":
        if (os && (os.labors?.length || os.laborsTMP?.length)) {
          resp += ` (${(os.labors.length || 0) + (os.laborsTMP.filter(labor => (labor.status === DESCRIPTIVE_ITEM_STATUS.DESCRIPTIVE_APPROVED_AND_ACTIVE || labor.status === DESCRIPTIVE_ITEM_STATUS.ACTIVE)).length || 0)})`;
        }
        break;
      case "ro_outsourced":
        if (os && os.thirdPartyServices && os.thirdPartyServices.length) {
          resp += ` (${os.thirdPartyServices.length})`;
        }
        break;
      default:
        break;
    }

    return resp;
  }

}
