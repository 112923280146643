import { Pipe, PipeTransform } from '@angular/core';
import { Part } from '../../interface/part';
import { MkgOSPart } from 'src/app/class/mkg-os-part';

@Pipe({
  name: 'roPartIncluded'
})
export class RoPartIncludedPipe implements PipeTransform {

  transform(roParts: (MkgOSPart)[], part: Part): boolean {
    if (!part || !roParts || !roParts.length){
      return false
    }

    return roParts.some(roPart => roPart.part && roPart.part.id === part.id)

  }

}
