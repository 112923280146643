import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed'
})
export class ToFixedPipe implements PipeTransform {

  public transform(value: any): any {
    const transformedValue = Number(value).toFixed(2);

    return transformedValue;
  }

}
