import { Pipe, PipeTransform } from '@angular/core';
import { CASHIER_OPERATION_TYPES } from '../lists/cashier-operation-types';
import { CashierHistoric } from '../../interface/cashier-historic';

@Pipe({
  name: 'cashierHistoricClass'
})
export class CashierHistoricClassPipe implements PipeTransform {

  /** Indicate if a CashierHistoric was 'pay' or 'receive'  */
  transform(cashier: CashierHistoric): 'pay' | 'receive' | undefined {

    if(!cashier){
      return undefined;
    }

    if (cashier.title && cashier.received) {
      if (cashier.title.type) {
        return cashier.isPay ? "pay" : "receive";
      }
    }
    if (cashier.event) {
      return cashier.event.eventType.isPay ? "pay" : "receive";
    }
    if (cashier.operation) {
      switch (CASHIER_OPERATION_TYPES[cashier.operation.type]) {
        case "Abertura":
        case "Suprimento":
          return "receive";
        case "Sangria":
        case "Fechamento":
          return "pay";
        default:
          return undefined
      }
    }
    return undefined
  }

}
